import React, {
  useState,
  useEffect,
  useLayoutEffect,
  type ReactNode,
  useRef,
} from "react";
import { type MyDialogStackParamList } from "./MyDialogsStack";
import {
  type ConversationUserResponse,
  type ConversationMetadataResponse,
  ConversationStatus,
  type ConversationUserReportResponse,
  WebsocketMessageType,
  type ConversationMessageResponse,
} from "../client/DialogClientModels";
import { useAuthContext } from "../AuthContext";
import { type NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  GiftedChat,
  type User,
  type IMessage,
  type ComposerProps,
  type SendProps,
  Composer,
  type SystemMessageProps,
  SystemMessage,
} from "react-native-gifted-chat";
import { ActivityIndicator, type FlatList, View } from "react-native";
import { makeGiftedChatMessage } from "../common/DialogUtil";
import { MyDialogBanner } from "./MyDialogBanner";
import { MyDialogTopMenu } from "./menu/MyDialogTopMenu";
import { useDialogApiContext } from "../DialogApiContext";
import { ErrorMessage } from "../common/ErrorMessage";
import styles from "../styles";
import { HeaderTitle } from "../common/HeaderTitle";
import { SignInToContinue } from "../common/SignInToContinue";
import { useFocusEffect } from "@react-navigation/native";

type Props = NativeStackScreenProps<MyDialogStackParamList, "MyDialogScreen">;

interface Conversation {
  metadata: ConversationMetadataResponse;
  users: ConversationUserResponse[];
  report?: ConversationUserReportResponse;
  messages: IMessage[];
  nextCursor?: string;
}

const BATCH_SIZE = 50;

const POLLING_BATCH_SIZE = 10;
const POLLING_INTERVAL_MS = 60000;

const MAX_MESSAGE_LENGTH = 2000;

const MIN_COMPOSER_HEIGHT = 28;
const MAX_COMPOSER_HEIGHT = 200;

export const MyDialogScreen: React.FC<Props> = ({ route, navigation }) => {
  const { dialogClient, latestWebsocketMessage } = useDialogApiContext();
  const { user, fetchUserStatus } = useAuthContext();

  const { conversationTopicId, conversationId, title } = route.params;
  const reload = Number(route.params.reload) || 0;

  const [conversation, setConversation] = useState<Conversation>();
  const [isLoadingEarlier, setIsLoadingEarlier] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [composerHeight, setComposerHeight] =
    useState<number>(MIN_COMPOSER_HEIGHT);
  const [pollingIntervalMs, setPollingIntervalMs] =
    useState<number>(POLLING_INTERVAL_MS);

  const latestPolledMessageId = useRef<string | undefined>(undefined);
  const emptyPollCount = useRef<number>(0);
  const giftedChatRef = useRef<FlatList<IMessage> | null>(null);

  const setMessages = (
    setMessageFn: (
      previousMessages: IMessage[],
      previousConversation: Conversation,
    ) => IMessage[],
    setNextCursorFn: (previousCursor?: string) => string | undefined = (
      previousCursor?: string,
    ) => previousCursor,
  ): void => {
    setConversation((previousConversation) => {
      if (!previousConversation) {
        return previousConversation;
      }

      const {
        messages: previousMessages,
        nextCursor: previousCursor,
        ...restOfConversation
      } = previousConversation;

      return {
        messages: setMessageFn(previousMessages, previousConversation),
        nextCursor: setNextCursorFn(previousCursor),
        ...restOfConversation,
      };
    });
  };

  const setIsDeleted = (): void => {
    setConversation((previousConversation) => {
      if (!previousConversation) {
        return previousConversation;
      }

      const { metadata, ...restOfConversation } = previousConversation;

      return {
        metadata: { ...metadata, status: ConversationStatus.DELETED },
        ...restOfConversation,
      };
    });
  };

  useLayoutEffect(() => {
    if (conversation) {
      setConversation(undefined);
    }

    if (user?.userId) {
      fetchConversation().catch(console.error);
    }
  }, [conversationId, user?.userId, reload]);

  useEffect(() => {
    if (conversation && conversation.messages.length > 0) {
      setTimeout(() => {
        // There is a bug with gifted chat where messages are sometimes empty on mobile web
        // If the messages have loaded but aren't showing, we reload the screen by adding or
        // changing the "reload" parameter
        if (!giftedChatRef.current?.props?.data?.length) {
          navigation.navigate("MyDialogScreen", {
            conversationTopicId,
            conversationId,
            title,
            reload: String(reload + 1),
          });
        }
      }, 1000);
    }
  }, [conversation === undefined]);

  useEffect(() => {
    if (conversation) {
      navigation.setOptions({
        headerTitle: () => <HeaderTitle title={conversation.metadata.title} />,
        title: `Dialog with ${conversation.metadata.title}`,
        headerRight: () => (
          <MyDialogTopMenu
            metadata={conversation?.metadata}
            report={conversation?.report}
            users={conversation?.users}
            userId={user?.userId}
            fetchConversation={fetchConversation}
            setIsDeleted={setIsDeleted}
          />
        ),
      });
    } else if (title) {
      navigation.setOptions({
        headerTitle: () => <HeaderTitle title={title} />,
        title: `Dialog with ${title}`,
      });
    }
  }, [conversation?.metadata, conversation?.report, conversation?.users]);

  useEffect(() => {
    if (latestWebsocketMessage) {
      switch (latestWebsocketMessage.type) {
        case WebsocketMessageType.NEW_MESSAGE:
          if (
            latestWebsocketMessage.message.conversationId === conversationId &&
            latestWebsocketMessage.message.userId !== user?.userId &&
            conversation?.users.length === 2 &&
            !conversation?.messages.some(
              ({ _id }) => _id === latestWebsocketMessage.message.messageId,
            )
          ) {
            setMessages((previousMessages, previousConversation) => {
              return GiftedChat.append(previousMessages, [
                makeGiftedChatMessage(
                  latestWebsocketMessage.message,
                  previousConversation.users,
                ),
              ]);
            });
          }
          break;
        default:
          // Reload the conversation on any other message type
          if (
            latestWebsocketMessage.message.conversationId === conversationId
          ) {
            fetchConversation().catch(console.error);
          }
      }
    }
  }, [latestWebsocketMessage]);

  useFocusEffect(
    React.useCallback(() => {
      pollForNewMessagesSync();

      const intervalId = setInterval(pollForNewMessagesSync, pollingIntervalMs);

      return () => {
        clearInterval(intervalId);
      };
    }, [conversation?.messages, isLoadingEarlier, pollingIntervalMs]),
  );

  const fetchConversation = async (): Promise<void> => {
    setIsLoadingEarlier(true);
    try {
      const { messages, users, ...restOfConversation } =
        await dialogClient.getConversation(
          conversationTopicId,
          conversationId,
          BATCH_SIZE,
        );

      latestPolledMessageId.current = messages.records[0]?.messageId;

      setConversation({
        messages: messages.records.map((message) =>
          makeGiftedChatMessage(message, users),
        ),
        nextCursor: messages.nextCursor,
        users,
        ...restOfConversation,
      });
      setErrorMessage(undefined);
    } catch (error) {
      setErrorMessage("Could not load dialog");
    } finally {
      setIsLoadingEarlier(false);
    }
  };

  const onSendSync = (newMessages: IMessage[] = []): void => {
    sendMessages(newMessages).catch(console.error);
  };

  const sendMessage = async (newMessage: IMessage): Promise<void> => {
    setComposerHeight(MIN_COMPOSER_HEIGHT);
    setMessages((previousMessages) => {
      return GiftedChat.append(previousMessages, [
        { ...newMessage, pending: true },
      ]);
    });

    try {
      const sentMessage = await dialogClient.addConversationMessage(
        conversationTopicId,
        conversationId,
        newMessage.text,
      );

      setMessages((previousMessages, previousConversation) => {
        return previousMessages.map((message) =>
          message._id === newMessage._id
            ? makeGiftedChatMessage(sentMessage, previousConversation.users)
            : message,
        );
      });
    } catch (error) {
      setMessages((previousMessages) => {
        return previousMessages.map((message) =>
          message._id === newMessage._id
            ? {
                ...message,
                text: `Could not send message: "${message.text}"`,
                system: true,
              }
            : message,
        );
      });
    }
  };

  const sendMessages = async (newMessages: IMessage[]): Promise<void> => {
    for (const message of newMessages) {
      await sendMessage(message);
    }
  };

  const fetchEarlierMessages = async (cursor?: string): Promise<void> => {
    try {
      if (conversation?.nextCursor) {
        const { records, nextCursor } =
          await dialogClient.getMessagesInConversation(
            conversationTopicId,
            conversationId,
            BATCH_SIZE,
            "descending",
            cursor,
          );

        setMessages(
          (previousMessages, previousConversation) => {
            const earlierMessages = records.map((message) =>
              makeGiftedChatMessage(message, previousConversation.users),
            );
            return GiftedChat.prepend(previousMessages, earlierMessages);
          },
          () => nextCursor,
        );
      }
      setErrorMessage(undefined);
    } catch (error) {
      setErrorMessage("Could not load earlier messages");
    }
  };

  const pollForNewMessages = async (): Promise<void> => {
    if (conversation && !isLoadingEarlier) {
      const newMessageResponses: ConversationMessageResponse[] = [];
      let cursor: string | undefined;

      do {
        const { records, nextCursor } =
          await dialogClient.getMessagesInConversation(
            conversationTopicId,
            conversationId,
            POLLING_BATCH_SIZE,
            "descending",
            cursor,
          );

        const additionalMessages = latestPolledMessageId.current
          ? records.filter(
              (message) =>
                latestPolledMessageId.current &&
                message.messageId > latestPolledMessageId.current,
            )
          : records;

        if (additionalMessages.length > 0) {
          newMessageResponses.push(...additionalMessages);
          cursor = nextCursor;
        } else {
          cursor = undefined;
        }
      } while (cursor);

      // No new messages
      if (newMessageResponses.length === 0) {
        // Every 1 minute for the first hour without new messages
        // Every 10 minutes for the next 6 hours
        // Every hour after that
        const pollingIntervalMultiple =
          emptyPollCount.current < 60
            ? 1
            : emptyPollCount.current < 120
              ? 10
              : 60;
        setPollingIntervalMs(POLLING_INTERVAL_MS * pollingIntervalMultiple);

        emptyPollCount.current++;

        return;
      }

      emptyPollCount.current = 0;
      setPollingIntervalMs(POLLING_INTERVAL_MS);

      const messagesSinceLastPoll = latestPolledMessageId.current
        ? conversation.messages.filter(
            ({ _id }) =>
              latestPolledMessageId.current &&
              _id > latestPolledMessageId.current,
          )
        : conversation.messages;

      latestPolledMessageId.current = newMessageResponses[0].messageId;

      // All new messages have already been loaded
      if (newMessageResponses.length === messagesSinceLastPoll.length) {
        return;
      }

      setMessages((previousMessages, previousConversation) => {
        const newMessages = newMessageResponses
          .filter(
            ({ messageId }) =>
              !previousMessages.some(({ _id }) => _id === messageId),
          )
          .map((newMessage) =>
            makeGiftedChatMessage(newMessage, previousConversation.users),
          );

        return GiftedChat.append(
          previousMessages.slice(messagesSinceLastPoll.length),
          newMessages,
        );
      });
    }
  };

  const pollForNewMessagesSync = (): void => {
    pollForNewMessages().catch(console.error);
  };

  const onLoadEarlier = (): void => {
    setIsLoadingEarlier(true);
    // Load more messages when reaching the end of the list
    if (conversation?.nextCursor) {
      fetchEarlierMessages(conversation.nextCursor)
        .catch(console.error)
        .finally(() => {
          setIsLoadingEarlier(false);
        });
    }
  };

  const getUser = (): User | undefined => {
    return user?.userId ? { _id: user.userId } : undefined;
  };

  const renderInputToolbar = (): ReactNode => {
    return <></>;
  };

  const shouldRenderInputToolbar = (): boolean => {
    return (
      !conversation ||
      conversation.metadata.status === ConversationStatus.PRIVATE
    );
  };

  const renderFooter = (): ReactNode => {
    return (
      <View style={{ height: composerHeight - MIN_COMPOSER_HEIGHT }}></View>
    );
  };

  const renderComposer = (
    props: ComposerProps & {
      onSend: SendProps<IMessage>["onSend"];
      text: SendProps<IMessage>["text"];
    },
  ): ReactNode => {
    return (
      <Composer
        {...props}
        composerHeight={composerHeight}
        onInputSizeChanged={(layout) => {
          setComposerHeight(
            Math.max(
              Math.min(layout.height, MAX_COMPOSER_HEIGHT),
              MIN_COMPOSER_HEIGHT,
            ),
          );
        }}
        textInputProps={{
          ...props.textInputProps,
          multiline: true,
          onSubmitEditing: () => {
            if (props.text && props.onSend) {
              props.onSend({ text: props.text.trim() }, true);
            }
          },
        }}
      />
    );
  };

  const renderSystemMessage = (
    props: SystemMessageProps<IMessage>,
  ): ReactNode => (
    <SystemMessage
      {...props}
      wrapperStyle={styles.errorContainer}
      textStyle={styles.errorText}
    />
  );

  if (fetchUserStatus === "PENDING") {
    return <></>;
  }

  return user?.userId && fetchUserStatus === "COMPLETE" ? (
    <View style={{ flex: 1 }}>
      {conversation && user.userId && (
        <MyDialogBanner
          metadata={conversation.metadata}
          report={conversation.report}
          users={conversation.users}
          userId={user.userId}
          isEmpty={conversation.messages.length === 0}
        />
      )}
      {errorMessage && (
        <View style={{ marginTop: 10, maxWidth: 1000, alignSelf: "center" }}>
          <ErrorMessage errorMessage={errorMessage} />
        </View>
      )}
      {conversation ? (
        <GiftedChat
          // Hack to prevent messages from being rendered upside down
          messagesContainerStyle={{ transform: [{ scaleY: -1 }] }}
          messageContainerRef={giftedChatRef}
          messages={conversation.messages}
          maxInputLength={MAX_MESSAGE_LENGTH}
          onSend={onSendSync}
          user={getUser()}
          showUserAvatar={false}
          renderUsernameOnMessage={true}
          isLoadingEarlier={isLoadingEarlier}
          onLoadEarlier={onLoadEarlier}
          loadEarlier={conversation?.nextCursor !== undefined}
          infiniteScroll={true} // Doesn't work on web
          renderInputToolbar={
            shouldRenderInputToolbar() ? undefined : renderInputToolbar
          }
          renderSystemMessage={renderSystemMessage}
          renderComposer={renderComposer}
          renderFooter={renderFooter}
        />
      ) : (
        <ActivityIndicator size="small" style={{ margin: 20 }} />
      )}
    </View>
  ) : (
    <SignInToContinue action="view this dialog" />
  );
};
